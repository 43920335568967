import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import JoinVendor from './JoinVendor';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`navbar ${isOpen ? 'active' : ''}`}>
      <div className="navbar-brand">
        <Link to="/">
          <img src="/img/logo-dash.png" alt="logo" className='logo' style={{ width: "150px", marginLeft: "12px" }} />
        </Link>
      </div>
      <div className="navbar-menu navmob">
      <button
  className={`navbar-toggle ${isOpen ? 'active' : ''}`}
  onClick={toggleNavbar}
  style={{ fontSize: '28px' }}
>
  {isOpen ? '✕' : '☰'} {/* ✕ is the "X" icon, ☰ is the hamburger icon */}
</button>

        <ul className={`navbar-links ${isOpen ? 'active' : ''}`}>
          <li> <Link to="/">Home</Link> </li>
          <li> <Link to="/about">About</Link> </li>
          <li> <Link to="/filter">Search Hall</Link>  </li>
          <li> <Link to="/bloglist">Blogs</Link>  </li>
          <li> <Link to="/contact">Contact</Link>  </li>
          <JoinVendor/>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
