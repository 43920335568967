import React from 'react'
import BackButton from './BackButton'
import Footer from './Footer'
import Navbar from './Navbar'




function About() {
  return (
    <React.Fragment>
<Navbar/>

<BackButton/>
         <section class="main_heading my-5">
         <div class="section-title">
                                <h2>About Us</h2>
                            </div>
        <div class="container">
            <div class="row my-5">
                <div class="col-lg-6 ">
                    <figure>
                        <img  src="img/img1.jpg" alt="about images" class="img-fluid about_img"  style={{width:"100%",height:"280px"}} / >
                    </figure>
                </div>
                <div class="col-lg-6" >
                      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti corporis dolore, veniam excepturi Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti corporis dolore, veniam excepturi dignissimos vitae magnam? Minus repellat, incidunt, aspernatur itaque aliquid quam rerum inventore quisquam totam quae in maxime earum blanditiis voluptatibus libero delectus ad repudiandae, ipsum dolor quaerat culpa id perspiciatis nobis omnis. Iste unde voluptatum qui repellendus voluptas corrupti itaque. Aspernatur, eos repellendus totam ut dolore saepe!</p>
                </div>
            </div>
        </div>

        
    </section>

    <Footer/>

    </React.Fragment>
  )
}

export default About