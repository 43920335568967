import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from './pages/Index';
import Filter from './pages/Filter';
import Description from './pages/Desc';
import Slider from './pages/Slider';
import SliderExample from './pages/SliderExample';
import Contact from './pages/Contact';
import Category from './pages/Category';
import Emailex from './pages/Emailex';
import FilterYoutube from './pages/filteryoutube';
import Loginnew from './pages/Loginnew';
import Search from './pages/Search';
import AddressDesc from './pages/AddressDesc';
import MyForm from './pages/MyForm';
import About from './pages/About';
import Blog from './pages/Blog';
import BlogDesc from './pages/BlogDesc';
import BlogList from './pages/BlogList';
import Servicedescription  from './pages/Servicedescription';
import JoinVendor from './pages/JoinVendor';
import Testimonial from './pages/Testimonial';
import AllTestimonial from './pages/AllTestimonial';
function App() {
  return (
    <>
    <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/about" element={<About />} />
          <Route path="/filter" element={<Filter />} />
          <Route path="/description" element={<Description />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/blog/:id" element={<BlogDesc/>} />

          <Route path="/bloglist" element={<BlogList/>} />

          <Route path="/slider" element={<Slider />} />
          <Route path="/sliderexample" element={<SliderExample />} />
          <Route path="/contact" element={<Contact />} />
          <Route path='/description/:id' element={<Description/>}/>

          <Route path='/city/:address' element={<AddressDesc/>}/>

          <Route path="/category/:id" element={<Category />} />
 
          <Route path="/email" element={<Emailex />} />

          <Route path="/samplefilter" element={<FilterYoutube />} />

          <Route path="/loginnew" element={<Loginnew />} />
          <Route path="/search" element={<Search />} />
          <Route path="/according" element={<MyForm />} />
          <Route path="/servicedescription" element={<Servicedescription/>}/>

          <Route path="/joinvendor" element={<JoinVendor/>} />

          <Route path="/alltestimonial/" element={<AllTestimonial />} />
      </Routes>
      </BrowserRouter>
      </>
  );
}

export default App;




