import React from 'react'
// import Indexjs from './Indexjs'
import Navbar from './Navbar'
import Carousel from 'react-multi-carousel';

import {responsive} from './Carouselddata';
import Services from './Services';
import SliderExample from './SliderExample';
import About from './About';
import TopCarousel from './TopCarousel';
import Testimonial from './Testimonial';
import Footer from './Footer';
import { Link, useNavigate } from "react-router-dom";
import Blog from './Blog';
function Index() {

  const navigate = useNavigate();

  return (
    <React.Fragment>



      <div>
        {/* nav start */}
     <Navbar/>


       <TopCarousel/>

        <section className="web-body">
        <section class="main_heading my-5">
         <div class="section-title">
         
                                <h2 className='text-center'>About Us</h2>
                            </div>
        <div class="container">
            <div class="row my-5">
                <div class="col-lg-6 ">
                    <figure>
                        <img  src="img/img1.jpg" alt="about images" class="img-fluid about_img"  style={{width:"100%",height:"280px"}} / >
                    </figure>
                </div>
                <div class="col-lg-6" >
                      <p >Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti corporis dolore, veniam excepturi Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti corporis dolore, veniam excepturi dignissimos vitae magnam? Minus repellat, incidunt, aspernatur itaque aliquid quam rerum inventore quisquam totam quae in maxime earum blanditiis voluptatibus libero delectus ad repudiandae, ipsum dolor quaerat culpa id perspiciatis nobis omnis. Iste unde voluptatum qui repellendus voluptas corrupti itaque. Aspernatur, eos repellendus totam ut dolore saepe!</p>
                      <button type="button" class="btn btn-sm btn-outline-info" data-toggle="tooltip" data-placement="right" title="Who I am "><Link to="/about">Check More</Link></button>
                </div>
            </div>
        </div>


    </section>







<Services/> 




<div class="container">
  <SliderExample/>











            </div>





       <Blog/>







          {/* TESTIMONIALS Start*/}
    <Testimonial/>



       <Footer/>
        </section>

      </div>

{/* <Indexjs/> */}

    </React.Fragment>
  )
}

export default Index
