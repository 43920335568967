import React, { useEffect, useState } from 'react'
import Indexcss from './Indexcss'
import Indexjs from './Indexjs'
import Navbar from './Navbar'
import { useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import axios from 'axios';
import BackButton from './BackButton';
import YouTube from 'react-youtube';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Footer from './Footer';
import Spinner from './spinner.gif';


function  Desc() {

    const { id } = useParams();
    const pidd = id.id;
    //console.log("pid",pidd);
  
    
    const [product, setProduct] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [event_date, setEvent_date] = useState(null);
    const [mobile, setMobile] = useState('');
    const [guest_count, setGuest_count] = useState('');
    const [plate_cost, setPlate_cost] = useState('');
    const [address, setAddress] = useState('');
    const [food, setFood] = useState('');
    const [msg, setMsg] = useState('');
    const [event_time,setEventTime] = useState('');
    const [bandvendorfetch, setBandVendorFetch] = useState([]);
    const [cateringvendorfetch, setCateringVendorFetch] = useState([]);
    const [chefvendorfetch, setChefVendorFetch] = useState([]);
    const [decorationvendorfetch, setDecorationVendorFetch] = useState([]);
    const [makeupvendorfetch, setMakeupVendorFetch] = useState([]);
    const [photogvendorfetch, setPhotogVendorFetch] = useState([]);
    const [eventmngvendorfetch, setEventmngVendorFetch] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [loading, setLoading] = useState(false);

  //  const [event_date, setEvent_date] = useState(null);

    const [hallid, setHallid] = useState(''); // Initialize hallid state
  
 const [hname, setHname] = useState('');

  const [nameError, setNameError] = useState('');
const [emailError, setEmailError] = useState('');
const [mobileError, setMobileError] = useState('');
const [foodError, setFoodError] = useState('');
const [platError, setPlatError] = useState('');
const [timeError,setTimeError]  = useState('');
const [dateError,setdateError]  = useState('');
const [countError,setCountError]  = useState('');

  useEffect(() => {
    fetchDescription();
    hallidshow();
  }, []);




  function handleChange(e) {
    if (e.target.name === 'name') {
      setName(e.target.value); 
    }
    else if (e.target.name === 'email') {
        setEmail(e.target.value);
    } else if (e.target.name === 'event_date') {
        setEvent_date(e.target.value);
    } else if (e.target.name === 'mobile') {
        setMobile(e.target.value);
    }  else if (e.target.name === 'guest_count') {
        setGuest_count(e.target.value);
    } else if (e.target.name === 'plate_cost') {
        setPlate_cost(e.target.value);
    } 
    else if (e.target.name === 'address') {
        setAddress(e.target.value);
    } 
    else if (e.target.name === 'food') {
        setFood(e.target.value);
    } 
    else if (e.target.name === 'msg') {
        setMsg(e.target.value);
    }  else if (e.target.name === 'hall_id') {
        setHallid(e.target.value);
    } else if (e.target.name === 'hall_name') {
        setHname(e.target.value);
    } 
  }


  function closeModal() {
    setShowModal(false);
    setName('');
    setEmail('');
    setEvent_date('');
    setMobile('');
    setGuest_count('');
    setPlate_cost('');
    setAddress('');
    setFood('');
    setMsg('');
    setEventTime('');
    setHallid('');
    setHname('');
  }
  const handleDateChange = (date) => {
    setEvent_date(date);
  };
  const today = new Date();
  const nextDate = new Date(today);
  nextDate.setDate(today.getDate() + 2);



//   function handleSubmit(e) {
//     e.preventDefault();

//     const data = new FormData();
//     data.append('name', name);
//     data.append('event_date', event_date);
//     data.append('mobile', mobile);
//     data.append('guest_count', guest_count);

//     data.append('plate_cost', plate_cost);
//     data.append('address', address);
//     data.append('food', food);
//     data.append('msg', msg);
//     data.append('event_time', event_time);
    
//     axios.post('https://api.bookmyfunctionhall.com/api/enquiry', data)
//     .then((resp) => {
//         if (resp.data && resp.data[0].status === 'success') {
//             toast.success('Enquiry Sent Successfully');
//             setShowModal(false); // Close the modal
//             // Reset form fields
//             setName('');
//             setEvent_date('');
//             setMobile('');
//             setGuest_count('');
//             setPlate_cost('');
//             setAddress('');
//             setFood('');
//             setMsg('');
//             setEventTime();
//         } else {
//             //console.log('Error');
//             toast.error('Error occurred while adding data');
//         }
//     })
//     .catch((error) => {
//         //console.log('Error', error);
//         toast.error('An error occurred while making the request');
//     });
// }





















const nameRegex = /^[A-Za-z\s]+$/;
const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
const mobileRegex = /^\d{10}$/;














function handleSubmit(e) {
    e.preventDefault();


    setNameError('');
    setEmailError('');
    setMobileError('');
    setFoodError('');
    setPlatError('');
    setTimeError('');
    setdateError('');
    setCountError('')

    if (!name.match(nameRegex)) {
      setNameError('Name is invalid');
    }
  
    // Validate the "Email" field
    if (!email.match(emailRegex)) {
      setEmailError('Email is invalid');
    }
  
    // Validate the "Mobile No" field
    if (!mobile.match(mobileRegex)) {
      setMobileError('Mobile No must be a 10-digit number');
    }
  // Validate the "Select Food" field
  if (food === '') {
    setFoodError('Please select an option');
  }

if(plate_cost ==='')
{
  setPlatError('Please select an option');
}

if(event_time ==='')
{
  setTimeError('Please select an option');
}
if(event_date ==='')
{
  setdateError('Please select date')
}
if(guest_count ==='')
{
  setCountError("Please select option")
}
    // If there are validation errors, don't submit the form
    if (!name || !email || !mobile || food === '' || nameError || emailError || mobileError || foodError ||platError ||timeError ||dateError ||countError) {
      // If any required field is empty or there are validation errors, don't submit the form
      return;
    }
    
    setLoading(true); // Start loading




    const data = new FormData();
    data.append('name', name);
    data.append('email', email);
    // data.append('event_date', event_date);
    data.append('event_date', event_date ? event_date.toLocaleDateString('en-GB') : null);

    data.append('mobile', mobile);
    data.append('guest_count', guest_count);
    data.append('plate_cost', plate_cost);
    data.append('address', address);
    data.append('food', food);
    data.append('msg', msg);
    data.append('event_time', event_time);
    data.append('hall_id', product.id); // 
    data.append('hall_name', product.hname);
   
    axios
    .post('https://api.bookmyfunctionhall.com/api/enquiry', data)
    .then((resp) => {
      if (resp.data && resp.data[0] && resp.data[0].status === 'success') {
        // Handle the case when data is stored successfully
        toast.success('Enquiry Sent Successfully');
        setShowModal(false);
        // Reset form fields
        setName('');
        setEmail('');
        setEventTime('');
        setMobile('');
        setGuest_count('');
        setPlate_cost('');
        setAddress('');
        setFood('');
        setMsg('');
        setHallid(''); // Clear hall_id when the form is reset
        setHname('');
        closeModal();
      } else {
        // Handle the case when data storage was not successful
        console.error('Error');
        toast.success('Enquiry Sent Successfully');
        closeModal();
      }
    })
    .catch((error) => {
      // Handle other error cases
      console.error('Error', error);
      toast.success('Enquiry Sent Successfully');
      // You might also reset the form fields or take other actions here
       closeModal();
    })
    .finally(() => {
      setLoading(false); // Stop loading
      setTimeout(() => {
        toast.dismiss(); // Dismiss the toast after 1 second
      }, 2000);
    });
  






  }



function fetchDescription() {   
    axios.get(`https://api.bookmyfunctionhall.com/api/halldesc/${id}`)
      .then((res) => {
        const data = res.data;
        //console.log(data);
       // console.log("images",product.images)
        setProduct(data[0]);
      })
      .catch((error) => {
        console.error(error);
      });
  }



  function hallidshow() {
    axios
      .get(`https://api.bookmyfunctionhall.com/api/hallidshow/${pidd}`)
      .then((res) => {
        const data = res.data;
        //console.log('data2', data);
        setHallid(data[0]); // Set the hall_id state with the correct hall ID
      })
      .catch((error) => {
        console.error(error);
      });
  }

  
// function hallidshow() {
//     axios.get(`https://api.bookmyfunctionhall.com/api/hallidshow/${pidd}`)
//       .then((res) => {
//         const data = res.data;
//         //console.log("data2",data);
//         setHallid(data[0]);
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }


//   function hallidshow() {
//     axios.get(`https://api.bookmyfunctionhall.com/api/hallidshow/${id}`)
//       .then((res) => {
//         const data = res.data;
//         //console.log("id",data);
//         if (data.length > 0) {
//           setHallid(data[0].hname);
//           //console.log("hii",setHallid(data[0].hname))
        
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }


function fetchVendorBand() {
  axios.get(`https://api.bookmyfunctionhall.com/api/bandvendorfetchfrontend/${id}`)
      .then((res) => {
          const data = res.data;
          setBandVendorFetch(data);
      })
      .catch((error) => {
          console.error(error);
      });
}





function fetchVendorCatering() {
  axios.get(`https://api.bookmyfunctionhall.com/api/cateringvendorfetchfrontend/${id}`)
      .then((res) => {
          const data = res.data;
          setCateringVendorFetch(data);
      })
      .catch((error) => {
          console.error(error);
      });
}

function fetchVendorChef() {
  axios.get(`https://api.bookmyfunctionhall.com/api/chefvendorfetchfrontend/${id}`)
      .then((res) => {
          const data = res.data;
          setChefVendorFetch(data);
      })
      .catch((error) => {
          console.error(error);
      });
}


function fetchVendorDecoration() {
  axios.get(`https://api.bookmyfunctionhall.com/api/decorationvendorfetchfrontend/${id}`)
      .then((res) => {
          const data = res.data;
          setDecorationVendorFetch(data);
      })
      .catch((error) => {
          console.error(error);
      });
}


function fetchVendorMakeup() {
  axios.get(`https://api.bookmyfunctionhall.com/api/makeupvendorfetchfrontend/${id}`)
      .then((res) => {
          const data = res.data;
          setMakeupVendorFetch(data);
      })
      .catch((error) => {
          console.error(error);
      });
}




function fetchVendorPhotog() {
  axios.get(`https://api.bookmyfunctionhall.com/api/photogvendorfetchfrontend/${id}`)
      .then((res) => {
          const data = res.data;
          setPhotogVendorFetch(data);
      })
      .catch((error) => {
          console.error(error);
      });
}



function fetchVendorEventMng() {
  axios.get(`https://api.bookmyfunctionhall.com/api/eventmngvendorfetchfrontend/${id}`)
      .then((res) => {
          const data = res.data;
          setEventmngVendorFetch(data);
      })
      .catch((error) => {
          console.error(error);
      });
}



  useEffect(()=>{
    setTimeout(() => {
    fetchDescription();
    hallidshow();
    fetchVendorBand();
    fetchVendorCatering();
    fetchVendorChef();
    fetchVendorDecoration();
    fetchVendorMakeup();
    fetchVendorPhotog();
    fetchVendorEventMng();
    setIsLoading(false); // Set isLoading to false after data is fetched
  }, 1000);
    

},[])



function getYoutubeVideoId(url) {
  // Regular expression to match the YouTube video ID
  const regex = /(?:\?v=|\/embed\/|\/watch\?v=|\/youtu.be\/|\/v\/|\/e\/|watch\?v%3D|v%2F|%2Fvideos%2F|%2Fwatch%2F|embed\%2F|embed\?video_id=|%2Fv%2F|embed\?v=)([a-zA-Z0-9_-]{11})/i;
  const match = url.match(regex);

  if (match && match[1]) {
      return match[1];
  } else {
      // Handle invalid URLs or other cases
      return "";
  }
}

  return (
    <React.Fragment>
        <Indexcss/>
        <Navbar/>
       

       <BackButton/>
             <ToastContainer />
          <section class="product-details spad">
        <div class="container">
            <div class="row">
            <h3 style={{textAlign:"center", marginBottom:"15px"}}>{product.hname}</h3>
                <div class="col-lg-6 col-md-6">
                    <div class="product__details__pic">
                        <div class="product__details__pic__item">
                       {/* Assuming product.images is a JSON-encoded string */}
                       {product.images ? (
                  <img
                    src={`https://api.bookmyfunctionhall.com/hall_upload/${JSON.parse(product.images)[0]}`}
                    alt="#"
                    style={{ width: "250px", height: "400px" }}
                  />
                ) : (
                  <Skeleton width={250} height={400} />
                )}


                        </div>
                        <div class="product__details__pic__slider owl-carousel">
                        {product.images ? (
                  JSON.parse(product.images).map((image, index) => (
                    <img
                      key={index}
                      data-imgbigurl={`https://api.bookmyfunctionhall.com/hall_upload/${image}`}
                      src={`https://api.bookmyfunctionhall.com/hall_upload/${image}`}
                      alt=""
                      style={{ width: "250px", height: "400px" }}
                    />
                  ))
                ) : (
                  <Skeleton width={250} height={400} count={4} />
                )}
                        </div>
                    </div>
                </div>



                
                <div class="col-lg-6 col-md-6">
                    <div class="product__details__text">
                        {/* <div class="product__details__rating">
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star"></i>
                            <i class="fa fa-star-half-o"></i>
                            <span>(18 reviews)</span>
                        </div> */}
                        {/* <div class="product__details__price">&#8377; {product.rent}</div> */}
                        {/* <p>{product.for}</p> */}

                        <div class="container">
    <ul class="list-group">
        <li class="list-group-item">
            <b class='act1'>Availability: </b> 
            <span>                                        
                {product.status === "active" ? (
                    <b class="text-success">Available</b>
                ) : (
                    <b class="text-danger">Not available</b>
                )}
            </span>
        </li>
        <li class="list-group-item"><b>Hall Type:</b> <span>
        {product.type ? product.type : <Skeleton width={100} />}

          </span></li>
          <li class="list-group-item"><b>Hall capacity:</b> <span>
        {product.capacity ? product.capacity : <Skeleton width={100} />}

          </span></li>
        
        <li class="list-group-item"><b>Address:</b> <span>
        {product.area ? product.area : <Skeleton width={100} />}

          </span></li>
        <li class="list-group-item"><b>Hall Capacity:</b> <span>
        {product.capacity ? product.capacity : <Skeleton width={100} />}

          </span></li>
        <li class="list-group-item"><b>Car Parking:</b> <span>
        {product.parkingcap ? product.parkingcap : <Skeleton width={100} />}

          </span></li>
        <li class="list-group-item"><b>Rooms: Available</b> <span>

          </span></li>
        {/* <li class="list-group-item"><b>Contact:</b> <span>
        {product.contact ? product.contact : <Skeleton width={100} />}

          </span></li> */}
        <li class="list-group-item"><b>Price:</b> <span>
        {product.rent ? product.rent : <Skeleton width={100} />}

          </span></li>
        <li class="list-group-item">
            <b>Share on: </b>
            <div class="share">
                <a href="#"><i class="fa fa-facebook"></i></a>
                <a href="#"><i class="fa fa-twitter"></i></a>
                <a href="#"><i class="fa fa-instagram"></i></a>
                <a href="#"><i class="fa fa-pinterest"></i></a>
            </div>
        </li>
    </ul>
</div>

                       
<button type="button"  className="btn btn-outline-light mt-2 ml-2" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} onClick={() => setShowModal(true)}>
    Book Enquiry
</button>

<div
  className={`modal fade ${showModal ? 'show d-block' : ''}`}
  id="exampleModal"
  tabIndex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>    <div className="modal-dialog" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Enquiry</h5>
                <button type="button" className="btn-close" aria-label="Close" onClick={closeModal}></button>
            </div>
            <div className="modal-body">

<form>
                    <div className='row'>
                      <div className='col-md-6'>
                      <div class="form-group">
              <label for="">Name</label>
              <input type="text" name="name" id=""  onChange={(e) => {
      setName(e.target.value);
      setNameError('');
    }}
    value={name}
    className={`form-control ${nameError ? 'is-invalid' : ''}`} class="form-control" placeholder=""/>
     {nameError && <div className="invalid-feedback">{nameError}</div>}
            </div>
           
          <input
  type="hidden"
  name="hall_id"
  value={hallid} // Use the hallid state here
/>

<input
  type="hidden"
  defaultValue={product.id}
  name="hall_id"
  className="form-control mb-2"
  readOnly // Add the readOnly attribute
/>



Hall Name <input
  type="text"
  defaultValue={product.hname}
  name="hall_name"
  className="form-control mb-2"
  readOnly // Add the readOnly attribute
/>









            <div class="form-group">
              <label for="">Email</label>
              <input type="text" name="email" id="" 
              
              onChange={(e) => {
                setEmail(e.target.value);
                setEmailError('');
              }}
              value={email}
              className={`form-control ${emailError ? 'is-invalid' : ''}`}
              placeholder=""
            />
            {emailError && <div className="invalid-feedback">{emailError}</div>}
            </div>

            {/* <div className="form-group">
      <label htmlFor="event_date">Event Date</label>
      <DatePicker
        selected={event_date}
        onChange={handleDateChange}
        minDate={new Date()} // Set the minimum date to today
        placeholderText="Select a date"
        className={`form-control ${dateError ? 'is-invalid' : ''}`}
        name="event_date"
      />
    </div> */}


<div className="form-group">
      <label htmlFor="event_date">Event Date</label>
      <DatePicker
        selected={event_date}
        onChange={date => setEvent_date(date)}
        minDate={nextDate} // Set the minimum date to the next date
        placeholderText="Select a date"
        className={`form-control ${dateError ? 'is-invalid' : ''}`}
        name="event_date"
      />
    </div>

            <div class="form-group">
  <label for="">Mobile No</label>
  <input
    type="text"
    name="mobile"
    onChange={(e) => {
      setMobile(e.target.value);
      setMobileError('');
    }}
    value={mobile}
    className={`form-control ${mobileError ? 'is-invalid' : ''}`}
    placeholder=""
  />
  {mobileError && <div className="invalid-feedback">{mobileError}</div>}
</div>
            <div class="form-group">
                <label for="">Guest Count</label>
                <select  className={`form-control ${countError ? 'is-invalid' : ''}`} name="guest_count" value={guest_count} id=""
                onChange={(e) => {
                  setGuest_count(e.target.value);
                  setCountError('');
                }}
                 >
                    <option >Select one</option>
                    <option value="0-200">0 - 200</option>
                    <option value="201-400">201 - 400</option>
                    <option value="401-100">401 - 1000</option>
                    <option value="< 2000">- 2000</option>
                </select>
                {countError && <div className="invalid-feedback">{countError}</div>}
            </div>
                      </div>
                      <div className='col-md-6'>
                      <div class="form-group">
                <label for=""> Per Plate cost</label>
                <select  class={`custom-select ${platError ? 'is-invalid' : ''}`} 
                
                name="plate_cost" value={plate_cost}  onChange={(e) => {
                  setPlate_cost(e.target.value);
                  setPlatError(e.target.value ? '' : 'Please select an option');
                }} id="">
                    <option >Select one</option>
                    <option value="200">200</option>
                    <option value="400">400</option>
                    <option value="500+">500+</option>
                </select>
            </div>
           
            <div class="form-group">
              <label for="">Your Address</label>
              <input type="text" name="address" value={address} onChange={(e)=>setAddress(e.target.value)} id="" class="form-control" placeholder=""/>
            </div>
            <div class="form-group">
  <label for="">Select Food Veg / Non - Veg</label>
  <select
    class={`custom-select ${foodError ? 'is-invalid' : ''}`}
    name="food"
    value={food}
    onChange={(e) => {
      setFood(e.target.value);
      setFoodError(e.target.value ? '' : 'Please select an option');
    }}
    id=""
  >
    <option value="">Select one</option>
    <option value="veg">Veg</option>
    <option value="non-veg">Non-Veg</option>
    <option value="both">Both</option>
  </select>
  {foodError && <div className="invalid-feedback">{foodError}</div>}
</div>



            <div class="form-group">
                <label for="">Select Event Session</label>
                <select
                
                class={`custom-select ${timeError ? 'is-invalid' : ''}`}
                
                name="event_time" value={event_time}
                 onChange={(e) => {
                  setEventTime(e.target.value);
                  setTimeError(e.target.value ? '' : 'Please select an option');
                }}
                
              
                 
                 
                 id="">
                    <option value="Morning - Lunch">Morning - Lunch</option>
                    <option value="Evening - Dinner">Evening - Dinner</option>
                    <option value="Both">Both</option>
                </select>
                {timeError && <div className="invalid-feedback">{timeError}</div>}
            </div>

            <div class="form-group">
              <label for="">Message</label>
              <textarea name="msg" placeholder="" 
												onChange={(e)=>{setMsg(e.target.value)}} value={msg} rows="3" className='form-control'></textarea>            </div>
           
                      </div>

                    </div>




      
           
           
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close" onClick={closeModal}>Close</button>
        {/* <button type="submit"  className="btn btn-outline-light" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} onClick={handleSubmit}>Submit Enquiry</button> */}
        <button
        type="submit"
        className="btn btn"
        style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }}
        disabled={loading}
        onClick={handleSubmit}
      >
        {loading ? (
          <img src={Spinner} alt="spinner" style={{ width: "30px" }} />
        ) : (
          'Submit'
        )}
      </button>
      </div>
    </div>
  </div>
</div>    















                    </div>
                </div>



                <div class="col-lg-12">
                    <div class="product__details__tab">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                                <a class="nav-link active" data-toggle="tab" href="#tabs-1" role="tab"
                                    aria-selected="true">Description</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#tabs-2" role="tab"
                                    aria-selected="false">Gallery</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" data-toggle="tab" href="#tabs-3" role="tab"
                                    aria-selected="false">Video <span>(1)</span></a>
                            </li>
                           {
                            decorationvendorfetch.length >0 && (
                              <li class="nav-item">
                              <a class="nav-link" data-toggle="tab" href="#tabs-4" role="tab"
                                  aria-selected="false">Decoration <span>({decorationvendorfetch.length})</span> </a>
                          </li>
                            )
                           }
                      
                              {makeupvendorfetch.length >0 && (
                              <li class="nav-item">
                              <a class="nav-link"  data-toggle="tab" href="#tabs-5" role="tab"
                                  aria-selected="false">Makeup <span >({makeupvendorfetch.length})</span></a>
                              </li>
                              )}
                           
                        </ul>
                        <div class="tab-content">
                            <div class="tab-pane active" id="tabs-1" role="tabpanel">
                                <div class="product__details__tab__desc">
                                    <h6>Description</h6>
                                    <p>{product.for}</p>
                                </div>
                            </div>
                            








                            <div class="tab-pane" id="tabs-2" role="tabpanel">
                            <div class="photo-gallery">
                                <div class="container">
                                    
                                    <div class="row photos mt-3">
                                        {product.images ? (
                                          JSON.parse(product.images).map((image, index) => (
                                            <div class="col-sm-6 col-md-4 col-lg-3 item d-flex my-3" key={index}>
                                        <a href={`https://api.bookmyfunctionhall.com/hall_upload/${image}`} data-lightbox="photos">

                                              <img
                                                src={`https://api.bookmyfunctionhall.com/hall_upload/${image}`}
                                                alt={`Image ${index + 1}`}
                                                style={{ width: '100%', height: '100%'}}
                                              />
                                          </a>
                                            </div>
                                          ))
                                        ) : (
                                          <p>No images available</p>
                                        )}
                                      </div>

                                </div>
                            </div>
                  </div>


                  <div className="tab-pane" id="tabs-3" role="tabpanel">
    <div className="product__details__tab__desc my-5">
        {product && product.video && (
            <iframe
                style={{ width: "100%", height: "300px" }}
                src={`https://www.youtube.com/embed/${getYoutubeVideoId(product.video)}`}
                title="Product Video"
            ></iframe>
        )}
    </div>
</div>

<div className="tab-pane" id="tabs-4" role="tabpanel">
  <div className="product__details__tab__desc my-5">
    <div className="row">
      {decorationvendorfetch.map((row) => (
        <div className="col-md-4" key={row.id}>
          <div className="card my-3"  style={{ height:"400px" }}>
            <iframe
              width="100%"
              height="300"
              src={`https://www.youtube.com/embed/${getYoutubeVideoId(row.video)}`}
              title="Product Video"
              frameBorder="0"
              allowFullScreen
            ></iframe>
            <div className="card-body">
              <h4 className="card-title">{row.name}</h4>
              <p className="card-text">{row.type}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>











<div className="tab-pane" id="tabs-5" role="tabpanel">
  <div className="product__details__tab__desc my-5">
    <div className="row">
      {makeupvendorfetch.map((row) => (
        <div className="col-md-4" key={row.id}>
          <div className="card my-3"  style={{ height:"400px" }}>
            <iframe
              width="100%"
              height="300"
              src={`https://www.youtube.com/embed/${getYoutubeVideoId(row.link)}`}
              title="Product Video"
              frameBorder="0"
              allowFullScreen
            ></iframe>
            <div className="card-body">
              <h4 className="card-title">Name of Makeup Artist - {row.bname}</h4>
              <p className="card-text">Type  - {row.for}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>















                        </div>
                    </div>
                </div>
            </div>






            
            <div className='row my-5'>
              <div className='col-md-6'>

              <div>
                
                
      {isLoading ? (
        <div>


          <h5><b>Event Management Details</b></h5>
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Type</th>
                <th>Contact</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><Skeleton width={100} /></td>
                <td><Skeleton width={100} /></td>
                <td><Skeleton width={100} /></td>
              </tr>
            </tbody>
          </table>
          
        </div>
      ) : (
      
        <div>
           
        {eventmngvendorfetch.length > 0 ? (
            <div>
            <h5><b>Event Management Details</b></h5>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Contact</th>
                </tr>
              </thead>
              <tbody>
                {eventmngvendorfetch.map((row) => (
                  <tr key={row.id}>
                    <td>{row.name ? row.name : <Skeleton width={100} />}</td>
                    <td>{row.type ? row.type : <Skeleton width={100} />}</td>
                    <td>{row.contact ? row.contact : <Skeleton width={100} />}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ):(
          <p></p>
        )}
        </div>

      )}

      
    </div>

              <div>
           
            {bandvendorfetch.length > 0 ? (
              <div> <h5><b>Band Details</b></h5>
            <table class="table table-bordered">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>type</th>
                            <th>details</th>
                         
                          </tr>
                        </thead>
                        <tbody>
                          {bandvendorfetch.map((row) => {
                            return (
                              <tr key={row.id}>
                                <td>{row.name}</td>
                                <td>{row.type}</td>
                                <td>{row.details}</td>
                               
                               
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                      </div>
            ):(
              <p></p>
            )}
            </div>

      
            <div>

{chefvendorfetch.length > 0 ? (
  <div><h5><b>Chef Details</b></h5>

<table class="table table-bordered">
            <thead>
              <tr>
                <th>Name</th>
                <th>type</th>
                <th>Speciality</th>
                <th>details</th>
             
              </tr>
            </thead>
            <tbody>
              {chefvendorfetch.map((row) => {
                return (
                  <tr key={row.id}>
                     <td>{row.name ? row.name : <Skeleton width={100} />}</td>
                     <td>{row.type ? row.type : <Skeleton width={100} />}</td>
                     <td>{row.speciality ? row.speciality : <Skeleton width={100} />}</td>
                     <td>{row.details ? row.details : <Skeleton width={100} />}</td>
                
                
                   
                   
                  </tr>
                );
              })}
            </tbody>
          </table>
          </div>
):(
  <p> </p>
)}



</div>


            
              </div>
              <div className='col-md-6'>

           



<div>
            {cateringvendorfetch.length > 0 ? (
              <div>            <h5><b>Catering  Details</b></h5>

  <table className="table table-bordered">
    <thead>
      <tr>
        <th>Name</th>
        <th>Type</th>
        <th>Details</th>
      </tr>
    </thead>
    <tbody>
      {cateringvendorfetch.map((row) => (
        <tr key={row.id}>
          <td>{row.caterer}</td>
          <td>{row.type}</td>
          <td>{row.details}</td>
        </tr>
      ))}
    </tbody>
  </table>
  </div>
) : (
  <p> </p>
)}
</div>






<div>
            {photogvendorfetch.length > 0 ? (
              <div>            <h5><b>Photography Details  Details</b></h5>

  <table className="table table-bordered">
    <thead>
      <tr>
        <th>Name</th>
        <th>Details</th>
      </tr>
    </thead>
    <tbody>
      {photogvendorfetch.map((row) => (
        <tr key={row.id}>
          <td>{row.name}</td>
          <td>{row.details}</td>
        </tr>
      ))}
    </tbody>
  </table>
  </div>
) : (
  <p> </p>
)}
</div>









              </div>

           
        </div>















            
        </div>




       
    </section>



    


    <Indexjs/>


    <Footer/>
    </React.Fragment>
  )
}

export default Desc