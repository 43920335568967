import React, { useState } from 'react';
import Navbar from './Navbar';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Footer from './Footer';
import Spinner from './spinner.gif';

function Contact() {
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');

  const [loading, setLoading] = useState(false);

  const nameRegex = /^[A-Za-z\s]+$/;
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const mobileRegex = /^\d{10}$/;

  const handleSubmit = (e) => {
    e.preventDefault();
    setNameError('');
    setEmailError('');
    setMobileError('');

    let valid = true;

    if (!name.match(nameRegex)) {
      valid = false;
      setNameError('Name is invalid');
    }

    if (!email.match(emailRegex)) {
      valid = false;
      setEmailError('Email is invalid');
    }

    if (!phone.match(mobileRegex)) {
      valid = false;
      setMobileError('Mobile No must be a 10-digit number');
    }

    if (valid) {
      setLoading(true); // Start loading

      const data = new FormData();
      data.append('name', name);
      data.append('email', email);
      data.append('phone', phone);
      data.append('message', message);

      axios
        .post('https://api.bookmyfunctionhall.com/api/Contact', data, {
          headers: { 'Content-Type': 'multipart/form-data' },
        })
        .then((resp) => {
          if (resp.data && resp.data[0].status === 'success') {
            toast.success('Contact Sent Successfully!!!',{ autoClose: 2000 });
            setName('');
            setEmail('');
            setPhone('');
            setMessage('');
            navigate('/contact');
          } else {
            toast.error('Error occurred while adding data');
          }
        })
        .catch((error) => {
          toast.error('An error occurred while making the request');
        })
        .finally(() => {
          setLoading(false); // Stop loading
        });
    }
  };
  return (
    <React.Fragment>
      <Navbar />
      <ToastContainer />
      <section>
        <div className="container">
          <div className="row" style={{ marginTop: "25px" }}>
            <div className="col-md-4">
              <h2 className="mb-5">Contact Us</h2>
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label for="name">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                      setNameError('');
                    }}
                    className={`form-control ${nameError ? 'is-invalid' : ''}`}
                    id="name"
                  />
                  {nameError && <div className="invalid-feedback">{nameError}</div>}
                </div>

                <div className="form-group">
                  <label for="email">Email</label>
                  <input
                    type="text"
                    name="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setEmailError('');
                    }}
                    className={`form-control ${emailError ? 'is-invalid' : ''}`}
                    id="email"
                  />
                  {emailError && <div className="invalid-feedback">{emailError}</div>}
                </div>

                <div className="form-group">
                  <label for="mobile">Mobile No</label>
                  <input
                    type="text"
                    name="mobile"
                    value={phone}
                    onChange={(e) => {
                      setPhone(e.target.value);
                      setMobileError('');
                    }}
                    className={`form-control ${mobileError ? 'is-invalid' : ''}`}
                    id="mobile"
                  />
                  {mobileError && <div className="invalid-feedback">{mobileError}</div>}
                </div>

                <div className="form-group">
                  <label for="message">Message</label>
                  <textarea
                    className="form-control"
                    name="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    id="message"
                    rows="3"
                  ></textarea>
                </div>

                {/* <button type="submit" className="btn btn-outline-primary">
                  Submit <i className="fa fa-telegram" aria-hidden="true" style={{ fontSize: "24px" }}></i>
                </button> */}

<button type="submit" className="btn btn" style={{ backgroundColor: "rgb(127, 1, 75)", color: "#fff" }} disabled={loading}>
                  {loading ? (
                    <img src={Spinner} alt="spinner" style={{ width:"30px" }} />
                  ) : (
                    'Submit'
                  )}
                </button>


                
              </form>
            </div>
            <div className="col-md-8">
              <img src="img/contact.jpg" className="p-5" alt="" style={{ width: "100%", marginTop: "-35px" }} />
            </div>
          </div>
        </div>
      </section>

    
      <Footer />
    </React.Fragment>
  );
}

export default Contact;
